<template>
  <div v-if="visible" class="toast">
    {{ message }}
    <button type="button" @click="hideToast">Cerrar</button>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const visible = ref(false);
const message = ref('');

function showToast(msg) {
  message.value = msg;
  visible.value = true;
  setTimeout(() => {
    visible.value = false;
  }, 8000);
}

function hideToast() {
  visible.value = false;
}

defineExpose({ showToast });
</script>

<style>
.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
}

.toast p {
  margin: 0;
  color: #333;
}

.toast button {
  background-color: #007bff;
  border: none;
  padding: 5px 10px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}
</style>
