<template>
  <div>
    <AccountStepper :current-step="4" />

    <div class="register__container invite-team">
      <p v-html="$t('register.invite-team.description')" />

      <Form @submit="onSubmit">
        <div class="register__form">
          <div
            v-for="(user, index) in teamList"
            :key="`user-team-list-${index}`"
            class="register__form-grid-cols-invite"
          >
            <div class="register__form-group">
              <Field
                label="name"
                :name="`team[${index}]name`"
                rules="required"
                v-model="user.name"
                v-slot="{ errorMessage, field }"
              >
                <input
                  :placeholder="$t('register.invite-team.form.name')"
                  data-test-id="inviteNameText"
                  autocomplete="off"
                  :class="{ error: errorMessage }"
                  v-bind="field"
                />
                <transition name="fade">
                  <span
                    data-test-id="inviteNameErrorLabel"
                    class="register__form-group-error"
                    v-if="errorMessage"
                    >{{ errorMessage }}</span
                  >
                </transition>
              </Field>
            </div>

            <div class="register__form-group">
              <Field
                label="email"
                :name="`team[${index}]email`"
                rules="required|email"
                v-model="user.email"
                v-slot="{ errorMessage, field }"
              >
                <input
                  type="email"
                  data-test-id="inviteEmailText"
                  :placeholder="$t('register.invite-team.form.email')"
                  autocomplete="off"
                  :class="{ error: errorMessage }"
                  v-bind="field"
                />
                <transition name="fade">
                  <span
                    data-test-id="inviteEmailErrorLabel"
                    class="register__form-group-error"
                    v-if="errorMessage"
                    >{{ errorMessage }}</span
                  >
                </transition>
              </Field>
            </div>

            <div class="register__form-group">
              <Field
                label="role"
                data-test-id="roleInviteSelect"
                :name="`team[${index}]role`"
                as="select"
                rules="required"
                v-model="user.role"
              >
                <option value="" disabled selected>
                  {{ $t('register.invite-team.form.role') }}
                </option>
                <option
                  v-for="(role, index) in roles"
                  :value="JSON.stringify(role)"
                  :key="`role-item-${index}`"
                  :disabled="
                    (role.name === SUPER_ROLE || role.name === ADMIN_ROLE) &&
                    isMaxLicensesExhausted &&
                    getRoleName(user.role) !== SUPER_ROLE &&
                    getRoleName(user.role) !== ADMIN_ROLE
                  "
                >
                  {{ role.display_name }}
                </option>
              </Field>
              <ErrorMessage :name="`team[${index}]role`" />
            </div>
            <span data-test-id="infoToolTip" class="register__form-icon">
              <tooltip :content="getRoleDescription(user.role)" position="top">
                <QuestionIcon />
              </tooltip>
            </span>
            <span
              data-test-id="deleteRowButton"
              :class="`register__form-icon icon-close ${
                teamList.length > 1 ? '' : 'disabled'
              }`"
              @click="removeField(index)"
            >
              <CloseIcon />
            </span>
          </div>

          <div class="register__form-grid-cols-invite">
            <div />
            <div />
            <div>
              <button
                data-test-id="addUserButton"
                class="button-secondary"
                type="button"
                @click="addField"
              >
                {{ $t('register.invite-team.form.add') }}
              </button>
            </div>
          </div>

          <button
            type="button"
            data-test-id="skipInviteButton"
            class="skip-button"
            @click="skip"
          >
            {{ $t('register.invite-team.skip') }}
          </button>

          <button
            data-test-id="inviteSubmitButton"
            type="submit"
            :disabled="isLoading"
          >
            {{ $t('register.invite-team.form.invite') }}
          </button>

          <ul v-if="Array.isArray(errorMsg)">
            <li
              v-for="(error, index) in errorMsg"
              :key="`error-msg-${index}`"
              class="register__form-default-error"
            >
              {{ error.message }}
            </li>
          </ul>

          <p
            v-if="errorMsg instanceof String || typeof errorMsg === 'string'"
            class="register__form-default-error text-center"
          >
            {{ errorMsg }}
          </p>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useUserStore } from '@/stores/user';
import AccountStepper from '@/components/account/AccountStepper.vue';
import { getRoles } from '@/services/user';
import QuestionIcon from '@/assets/images/question.svg';
import CloseIcon from '@/assets/images/close.svg';
import { ADMIN_ROLE, SUPER_ROLE } from '@/consts/roles';
import Tooltip from '@/components/Tooltip.vue';
import router from '@/router';
import { datadogRum } from '@datadog/browser-rum';

const store = useUserStore();
const { t } = useI18n();
const isLoading = ref(false);
const errorMsg = ref('');
const teamList = ref([{ name: '', email: '', role: null }]);
const roles = ref([]);
const maxLicenses = ref(2);
const company = ref(null);

const loadRoles = async () => {
  try {
    const roleList = await getRoles();
    roles.value = roleList.data;
  } catch (error) {
    console.error(error);
    datadogRum.addError(error);
  }
};

const addField = () => {
  teamList.value.push({ name: '', email: '', role: null });
};

const removeField = (index) => {
  if (teamList.value.length <= 1) {
    return;
  }

  teamList.value.splice(index, 1);
};

const onSubmit = async ({ team }) => {
  errorMsg.value = '';
  isLoading.value = true;

  try {
    await store.configInvite({
      company: company.value._id,
      workspace: company.value.workspace._id,
      data: team.map((user) => {
        const role = JSON.parse(user.role);

        return {
          ...user,
          role: role._id,
        };
      }),
    });

    router.push({ name: 'AccountInvited' });
  } catch (error) {
    console.error(error);

    if (error.response?.data?.errors?.length) {
      errorMsg.value = error.response.data.errors;
    } else if (error.response?.data?.message) {
      errorMsg.value = error.response.data.message;
    } else {
      errorMsg.value = t('default-error');
    }
    datadogRum.addError(error);
    isLoading.value = false;
  }
};

const skip = () => {
  const { token } = store.$state;

  store.clearStepsCreatingAccount();
  window.location.href = `${import.meta.env.VITE_ADMIN_BASE_URL}?data=${token}`;
};

const isMaxLicensesExhausted = computed(
  () =>
    teamList.value.reduce((accum, user) => {
      if (!user.role) {
        return accum;
      }

      const role = JSON.parse(user.role);

      return accum + (role?.name === SUPER_ROLE || role.name === ADMIN_ROLE);
    }, 0) >= maxLicenses.value
);

const getRoleName = (roleJSON) => {
  if (!roleJSON) {
    return null;
  }

  const role = JSON.parse(roleJSON);

  return role.name;
};

const getRoleDescription = (roleJSON) => {
  if (!roleJSON) {
    return 'Select a role';
  }

  const role = JSON.parse(roleJSON);

  return role.description;
};

onMounted(() => {
  loadRoles();

  if (store.$state.stepCreatingAccount !== 7) {
    store.setStepAccountURL();
    return;
  }

  const tier = store.$state.planSelected;
  company.value = store.$state.company;
  maxLicenses.value = (tier.licenses || 2) - 2;
  store.refreshToken(store.refresh);
});
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>
