<template>
  <button
    class="btn-primary"
    :type="props.type"
    :disabled="props.disabled"
    v-bind="$attrs"
    @click="emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script setup>
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
});

const emit = defineEmits(['click']);
</script>
