<template>
  <div>
    <AccountStepper :current-step="2" />

    <div class="register__container">
      <p v-html="$t('register.config-space.description')" />

      <Form @submit="onSubmit" :validation-schema="schema">
        <div class="register__form">
          <div class="register__form-group">
            <label for="workspace"
              >{{ $t('register.config-space.form.workspace') }} *</label
            >
            <Field
              name="workspace"
              v-slot="{ errorMessage, field }"
              v-model="workspace"
            >
              <input
                id="workspace"
                data-test-id="workspaceNameText"
                type="text"
                autocomplete="off"
                autofocus
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <transition name="fade">
                <span
                  data-test-id="workspaceErrorLabel"
                  class="register__form-group-error"
                  v-if="errorMessage"
                  >{{ errorMessage }}</span
                >
              </transition>
            </Field>
          </div>

          <div class="register__form-group">
            <label for="business"
              >{{ $t('register.config-space.form.business_name') }} *</label
            >
            <Field
              name="business"
              v-slot="{ errorMessage, field }"
              v-model="company"
            >
              <input
                id="business"
                data-test-id="companyNameText"
                type="text"
                autocomplete="off"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <transition name="fade">
                <span
                  data-test-id="companyErrorLabel"
                  class="register__form-group-error"
                  v-if="errorMessage"
                  >{{ errorMessage }}</span
                >
              </transition>
            </Field>
          </div>

          <div class="register__form-group">
            <label for="fiscal-country"
              >{{ $t('register.config-space.form.fiscal_country') }} *</label
            >
            <Field
              data-test-id="fiscalCountrySelect"
              name="fiscal_country"
              as="select"
              rules="required"
            >
              <option
                v-for="(country, index) in countries"
                :value="country.code3"
                :key="`country-item-${index}`"
              >
                {{ country.name }}
              </option>
            </Field>
          </div>

          <button
            data-test-id="spaceNextButton"
            type="submit"
            :disabled="isLoading"
          >
            {{ $t('register.config-space.form.next') }}
          </button>

          <p v-if="errorMsg.length" class="register__form-default-error">
            {{ errorMsg }}
          </p>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Field, Form } from 'vee-validate';
import { useUserStore } from '@/stores/user';
import router from '@/router';
import AccountStepper from '@/components/account/AccountStepper.vue';
import { datadogRum } from '@datadog/browser-rum';

const store = useUserStore();
const { t } = useI18n();
const isLoading = ref(false);
const errorMsg = ref('');
const workspace = ref('');
const company = ref('');
const countries = ref([]);

const schema = {
  workspace: 'required|min:2',
  business: 'required|min:2',
  fiscal_country: 'required',
};

const onSubmit = async (values) => {
  isLoading.value = true;

  try {
    const payload = {
      name: company.value,
      workspaceName: workspace.value,
      country: values.fiscal_country,
    };

    if (store.$state.company) {
      Object.assign(payload, {
        id: store.$state.company._id,
      });
    }

    await store.configWorkspace(payload);
    store.changeCountry(values.fiscal_country);
    store.setStepCreatingAccount(4);
  } catch (e) {
    datadogRum.addError(e);
    errorMsg.value = t('default-error');
    isLoading.value = false;
    return;
  }

  try {
    await store.configBilling({
      country: 'MEX',
    });
  } catch (e) {
    datadogRum.addError(e);
  }

  router.push({ name: 'ConfigPayment' });
};

const loadCountries = async () => {
  try {
    const response = await fetch('/json/countries_and_states.json');
    countries.value = await response.json();
  } catch (error) {
    console.error('Error loading JSON file:', error);
    datadogRum.addError(error);
  }
};

onMounted(() => {
  if (
    store.$state.stepCreatingAccount < 3 ||
    store.$state.stepCreatingAccount > 5
  ) {
    store.setStepAccountURL();
  }

  if (store.$state.company) {
    company.value = store.$state.company.name;
    workspace.value = store.$state.company.workspace?.name;
  }

  loadCountries();
});
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>
