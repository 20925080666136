<template>
  <div class="dropdown" ref="dropdownRef" v-bind="$attrs">
    <button
      data-test-id="dropdown-button"
      :class="[{ active: showDropdown }, 'dropdown-button']"
      type="button"
      @click="toggleDropdown"
    >
      <span>{{ optionSelected?.name }}</span>
      <svg viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" />
        <polyline
          points="208 96 128 176 48 96"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        />
      </svg>
    </button>
    <div
      v-if="showDropdown"
      data-test-id="planTypeDropdown"
      class="dropdown-options"
    >
      <template v-if="items.length">
        <div
          v-for="(item, index) in items"
          :key="`dropdown-item-${index}`"
          @click="changeOption(item)"
        >
          {{ item.name }}
        </div>
      </template>
      <template v-else>
        <div>No options</div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';

const dropdownRef = ref(null);
const showDropdown = ref(false);
const optionSelected = ref(null);

const props = defineProps({
  items: {
    type: Array,
    required: true,
    validator: (array) =>
      array.every((item) => 'name' in item && 'value' in item),
  },
  defaultOption: {
    type: Object,
    default: () => null,
  },
});

const emit = defineEmits(['on-change']);
const items = ref([...props.items]);
const defaultOption = ref(props.defaultOption);

const changeOption = (option) => {
  optionSelected.value = option;
  showDropdown.value = false;

  emit('on-change', option);
};

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;

  if (showDropdown.value) {
    document.addEventListener('click', onCloseDropdown);
  } else {
    document.removeEventListener('click', onCloseDropdown);
  }
};

const onCloseDropdown = (event) => {
  const dropdownElement = dropdownRef.value;

  if (dropdownElement && !dropdownElement.contains(event.target)) {
    showDropdown.value = false;
    document.removeEventListener('click', onCloseDropdown);
  }
};

onBeforeMount(() => {
  document.removeEventListener('click', onCloseDropdown);
});

onMounted(() => {
  if (defaultOption.value) {
    optionSelected.value = defaultOption.value;
  }
});
</script>
