import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import messages from '@intlify/vite-plugin-vue-i18n/messages';
import { datadogRum } from '@datadog/browser-rum';
import App from './App.vue';
import router from './router';
import './vee-validate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(
    createI18n({
      locale: 'es',
      fallbackLocale: 'es',
      messages,
    })
  );

const isProduction = import.meta.env.VITE_DD_ENVIRONMENT === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APPLICATIONID,
    clientToken: import.meta.env.VITE_DD_CLIENTTOKEN,
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DD_SERVICE,
    env: import.meta.env.VITE_DD_ENVIRONMENT,
    version: import.meta.env.PACKAGE_VERSION,
    defaultPrivacyLevel: 'mask-user-input',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });
}

app.mount('#app');
